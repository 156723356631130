<template>
  <div class="attenSum-list-page">
    <PocCardTable class="attendanceSummary topPocCardTable">
      <template v-slot:headerTitle>考勤汇总</template>
      <template v-slot:headerGroup>
        <el-button @click="refresh" class="resetButton">
          <i class="el-icon-refresh-left buttonrefflexBetw reshSpan"></i>
        </el-button>
        <el-button @click="filterQuery">查询</el-button>
      </template>
      <template>
        <PocSlider minheight="60px" maxHeight="350px" btntype="primary">
          <el-form
            :inline="true"
            size="mini"
            label-position="top"
            class="minWidthInput"
          >
            <el-form-item label="统计月份" label-position="top">
              <el-date-picker
                v-model="filterConfig.attendanceMonth"
                type="month"
                value-format="yyyy-MM"
                placeholder="选择统计月份"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="HFM Code" label-position="right">
              <el-select
                clearable
                v-model="filterConfig.hfmCode"
                filterable
                placeholder="请选择HFM Code"
                class="industry-selector"
              >
                <el-option
                  v-for="item in HFMCodeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="Assigment/project (Level2)"
              label-position="right"
            >
              <el-select
                clearable
                v-model="filterConfig.level2"
                filterable
                placeholder="请选择Level2"
                class="industry-selector"
              >
                <el-option
                  v-for="item in Level2s"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Description（Level3）" label-position="right">
              <el-select
                clearable
                v-model="filterConfig.level3"
                filterable
                placeholder="请选择Level3"
                class="industry-selector"
              >
                <el-option
                  v-for="item in Level3s"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Client" label-position="right">
              <el-input
                placeholder="请输入Client"
                v-model="filterConfig.client"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="英文名" label-position="right" class="flex-1">
              <el-input
                placeholder="请输入英文名"
                v-model="filterConfig.nameEn"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="中文名" label-position="right" class="flex-1">
              <el-input
                placeholder="请输入中文名"
                v-model="filterConfig.nameCn"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="付款批次流水" label-position="right">
              <el-select
                clearable
                v-model="filterConfig.payBatch"
                filterable
                placeholder="付款批次流水"
                class="industry-selector"
              >
                <el-option
                  v-for="item in payBatchList"
                  :key="Math.random()"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="付款状态" label-position="right">
              <el-select
                clearable
                v-model="filterConfig.status"
                filterable
                placeholder="请选择付款状态"
                class="industry-selector"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="国籍">
              <el-input
                placeholder="请输入国籍"
                v-model="filterConfig.nation"
                maxlength="50"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-form>
        </PocSlider>
      </template>
    </PocCardTable>
    <PocCardTable class="attendanceSummary">
      <div class="topButton">
        <div>
          <el-button
            role="confirmPayment"
            v-permission
            size="mini"
            type="primary"
            @click="surePay"
            >确认付款</el-button
          >
        </div>
        <div>
          <!-- !!!!!!!!!!!!!!!!!!权限增加了  感觉怎么没生效似的呢 -->
          <el-button
            role="redEnvelope"
            v-permission
            @click="setNewYearRedEnvelope"
            >设置新年红包</el-button
          >
          <el-button
            role="paymentExport"
            v-permission
            size="mini"
            :loading="queryLoading"
            @click="exportPayData"
            >付款导出</el-button
          >
          <el-button
            role="export"
            v-permission
            size="mini"
            :loading="queryLoading"
            @click="exportData"
            >导出</el-button
          >
        </div>
      </div>
      <PocTable
        v-loading="queryLoading"
        :data="tableData"
        stripe
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        @row-click="goDetail"
        @selection-change="selectChange"
        :max-height="100000000"
        ref="multipleTable"
        row-key="id"
      >
        <el-table-column
          :fixed="true"
          type="selection"
          :width="45"
          :reserve-selection="true"
        >
        </el-table-column>

        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span class="orgName" @click="selectOne(scope.row)">{{
              scope.row[item.key]
            }}</span>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <NewYearRedEnvelopeDialog
      ref="NewYearRedEnvelopeDialogRef"
      @close="queryApi"
    />
  </div>
</template>

<script>
import { getTheyearOptions } from '@/utils/util'
import { staffSumInfoExport, staffSumPayExport } from '@/api/attendance'
import { getHfmCodeList } from '@/api/organization'
import {
  getDescriptionLevel3,
  getAssigmentProjectLevel2
} from '@/api/personnelRequisition'
import NewYearRedEnvelopeDialog from './NewYearRedEnvelopeDialog.vue'
const { Vuex } = $PCommon
const { mapGetters } = Vuex
import pocSlider from '@/components/poc-slider/poc-slider'
export default {
  name: 'userManage',
  mixins: [$PCommon.TableMixin],
  components: { pocSlider, NewYearRedEnvelopeDialog },

  data() {
    return {
      maxHeight: '',
      exportMsg: '',
      pageConfig: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      tableData: [],
      columnConfig: [
        {
          key: 'idNumber',
          name: 'ID Number',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: true, // 固定列的位置(left, right)
          width: 100 // 默认宽度，像素
        },
        {
          key: 'attendanceMonth',
          name: 'Attendance Month',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: true, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'payBatch',
          name: '付款批次流水',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: true, // 固定列的位置(left, right)
          width: 110 // 默认宽度，像素
        },
        {
          key: 'nameCn',
          name: '中文名',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: true, // 固定列的位置(left, right)
          width: 90 // 默认宽度，像素
        },
        {
          key: 'nameEn',
          name: '英文名',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: true, // 固定列的位置(left, right)
          width: 90 // 默认宽度，像素
        },
        {
          key: 'isCn',
          name: '是中国人',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: true, // 固定列的位置(left, right)
          width: 80 // 默认宽度，像素
        },

        {
          key: 'nation',
          name: '国籍',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: true, // 固定列的位置(left, right)
          width: 60 // 默认宽度，像素
        },
        // {
        //   key: 'idNumber',
        //   name: '身份证号',
        //   dataType: 'string',
        //   visible: true,
        //   sortable: false,
        //   fixed: true, // 固定列的位置(left, right)
        //   width: 150 // 默认宽度，像素
        // },
        {
          key: 'payStatus',
          name: '付款状态',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 90 // 默认宽度，像素
        },
        {
          key: 'hfmCode',
          name: 'HFM Code',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 130 // 默认宽度，像素
        },
        {
          key: 'brand',
          name: 'Brand',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 80 // 默认宽度，像素
        },
        {
          key: 'orgName',
          name: '部门',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 150 // 默认宽度，像素
        },
        {
          key: 'location',
          name: '工作地',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'payTax',
          name: '居民纳税人',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 130 // 默认宽度，像素
        },
        {
          key: 'receiveName',
          name: '收款人',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'bank',
          name: '银行名称',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'bankAccount',
          name: '银行账号',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'attendanceDay',
          name: '工作天数',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'baseSalary',
          name: '工作薪资',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'salaryAmount',
          name: '工作总额',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'redEnvelopes',
          name: '新年红包',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'taxBase',
          name: '计税基数',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'taxStartPoint',
          name: '起征点',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'taxableAmount',
          name: '应税金额',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'iit',
          name: 'IIT',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        },
        {
          key: 'actualPaidSalary',
          name: '实付薪资',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false, // 固定列的位置(left, right)
          width: 120 // 默认宽度，像素
        }
      ],
      queryLoading: false,
      filterConfig: {
        attendanceMonth: '',
        nameCn: '',
        hfmCode: '',
        nameEn: '',
        nation: '',
        payBatch: '',
        status: '',
        level2: '',
        level3: '',
        client: ''
      },
      TreeSysOrgList: [], // 所属部门
      payBatchList: [],
      HFMCodeList: [],
      statusList: [
        { id: '0', name: '未付款' },
        { id: '1', name: '已付款' }
      ],
      formalStatusList: [
        { id: '1', name: '转正' },
        { id: '2', name: '未转正' }
      ],
      selections: [],
      Level2s: [],
      Level3s: [],
      upFlag: true
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    theyearOptions() {
      // 获取年份列表
      return getTheyearOptions()
    },
    columnOptions() {
      return this.columnConfig.filter((item) => item.visible)
    },
    ...mapGetters(['userInfo'])
  },

  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 380
    this.filterQuery()
    this.getpayBatch()
    this.getHfmCodes()
    this.getLevel2s()
    this.getLevel3s()
  },
  methods: {
    selectChange(val) {
      this.selections = val
    },
    setNewYearRedEnvelope() {
      this.$refs.NewYearRedEnvelopeDialogRef.show()
    },
    getHfmCodes() {
      getHfmCodeList().then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.HFMCodeList = res.data
          }
        }
      })
    },

    getLevel2s() {
      getAssigmentProjectLevel2().then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.Level2s = res.data
          } else {
            this.Level2s = []
          }
        }
      })
    },

    getLevel3s() {
      getDescriptionLevel3().then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.Level3s = res.data
          } else {
            this.Level3s = []
          }
        }
      })
    },

    onFilter(type, value) {},
    handleChange(value) {},
    upRow() {
      this.upFlag = !this.upFlag
    },
    close() {
      this.ModalFlagSuccess = false
      this.exportLeftSecond = 3
    },
    filterQuery() {
      this.queryApi()
    },
    refresh() {
      this.filterConfig = {
        attendanceDate: '',
        hfmCode: '',
        nameEn: '',
        nation: '',
        payBatch: '',
        status: '',
        level2: '',
        level3: '',
        client: ''
      }
      this.filterQuery()
    },

    dealStatus(status) {
      switch (status) {
        case '0': {
          return '有效'
        }
        case '1': {
          return '失效'
        }
        default: {
          return '-'
        }
      }
    },

    async getpayBatch() {
      const { data, code, msg } = await this.$Api.attensum.getpayBatch({})
      if (code === 200) {
        this.payBatchList = data
      }
    },

    surePay() {
      if (this.selections.length) {
        //将选中的数据处理为数组
        let idsArr = this.selections.map((v) => v.id).join(',')

        this.$Api.attensum
          .getsurePay({
            ids: idsArr
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '确认付款成功'
              })
            }

            this.$refs.multipleTable.clearSelection()
            this.queryApi()
          })
        // 成功之后全部取消选中状态
      } else {
        this.$message.warning('请选择付款项目')
      }
    },
    goDetail(row) {
      this.manageShow = true
      this.jumpParams = { jumpFlag: 'goDetail', singleId: row.id }
    },
    // 获取基础数据维护的列表
    async queryApi() {
      this.queryLoading = true
      const { data, code } = await this.$Api.attensum.getDataList({
        ...this.filterConfig,
        ...this.pageConfig
      })
      this.queryLoading = false
      if (code === 200) {
        this.tableData = data.list
        this.pageConfig.total = data.total
      }
    },
    selectAll(e) {
      if (e == true) {
        //全部选中
        this.seletAllFlag = true
      } else {
        this.seletAllFlag = false
      }

      this.$refs.multipleTable.toggleAllSelection()
    },
    selectOne(row) {
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    pageSizeChange(val) {
      this.$refs.multipleTable.clearSelection()
      this.pageConfig.pageSize = val
      this.queryApi()
    },

    exportPayData() {
      if (this.selections.length == 0) {
        this.$message({
          showClose: true,
          message: '请选择需要付款的人',
          type: 'warning'
        })
        return
      }

      const ids = []
      this.selections.forEach((item) => {
        ids.push(item.id)
      })
      let self = this
      setTimeout(()=>{
        self.$refs.multipleTable.clearSelection()
        self.queryApi()
      },1000)
      staffSumPayExport(ids.toString())

    },

    async exportData() {
      const data = {
        ...this.filterConfig,
        ids: this.selections.map((item) => item.id).toString()
      }
      staffSumInfoExport(data)
    },

    exportShowTip() {
      this.exportMsg = '报表数据'
      this.ModalFlagSuccess = true
      this.exportLeftSecond = 3
      const timer = setInterval(() => {
        if (this.exportLeftSecond > 0) {
          this.exportLeftSecond--
        } else {
          clearInterval(timer)
          this.ModalFlagSuccess = false
        }
      }, 1000)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {})
    window.removeEventListener('keyup', () => {})
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if ((e.code == 'Enter' || e.target == 13) && e.target.id == 'inputid') {
        console.log(e)
        this.filterQuery()
      }
    })
    // window.addEventListener('resize', () => {
    //   const h =
    //     document.getElementsByTagName('body')[0].clientHeight - 340 + 'px'
    //   this.maxHeight = h
    // })
  }
}
</script>
<style lang="scss" scoped>
.topButton {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
</style>
