<script setup>
import { ref,reactive, defineExpose, defineEmits } from 'vue'
import { saveEnvelope } from '@/api/attendance'
import { Message } from 'element-ui'
const emit = defineEmits(['close'])

let newYearData = reactive({
  fileUpVisible: false,
  redEnvelopeYear: '',
  amountRedEnvelope: 0,
  loading: false,
  dateEmployment: '',
  attendanceMonth: '',
})
let handleClose = () => {
  newYearData.fileUpVisible = false
  newYearData.redEnvelopeYear = ''
  newYearData.loading = false
  newYearData.amountRedEnvelope = 0
  newYearData.dateEmployment = ''
  newYearData.attendanceMonth = ''
}
let show = () => {
  newYearData.fileUpVisible = true
}
const isClose = ref(false);
let mclear = () => {
  newYearData.attendanceMonth = ''
}
let handleEnter = ()=> {
  if(newYearData.attendanceMonth.length) isClose.value = true;
  
}
let handleLeave = ()=> {
  isClose.value = false;
}
let submit = () => {
  const data = {
    ...newYearData
  }
  delete data.loading
  delete data.fileUpVisible
  newYearData.loading = true
  saveEnvelope(data).then((res) => {
    newYearData.loading = false
    if (res && res.code === 200) {
      Message.success('设置新年红包成功')
      emit('close')
      handleClose()
    }
  })
}
defineExpose({ handleClose, show })
</script>
<template>
  <el-dialog
    title="设置新年红包"
    :visible.sync="newYearData.fileUpVisible"
    width="435px"
    :destroy-on-close="true"
    :before-close="handleClose"
    custom-class="NewYearRedEnvelopeDialog"
  >
    <el-form
      ref="NewYearRedEnvelopeForm"
      :model="newYearData"
      label-width="100px"
    >
      <el-form-item label="选择年份">
        <el-date-picker
          v-model="newYearData.redEnvelopeYear"
          type="year"
          value-format="yyyy"
          placeholder="选择年份"
          style="width:235px;"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="填写金额">
        <el-input-number
          v-model="newYearData.amountRedEnvelope"
          controls-position="right"
          :min="0"
          style="width:235px;"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="要求在职日期">
        <el-date-picker
          v-model="newYearData.dateEmployment"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择要求在职日期"
          style="width:235px;"
        >
        </el-date-picker>
      </el-form-item>
      <div class="tips">实习生必须在该日期处于在职状态，才享有新年红包</div>
      <el-form-item label="应用考勤月份">
        
        <div class="mbox" @mouseenter="handleEnter" @mouseleave="handleLeave">
          <i class="el-input__icon el-icon-date"></i>
          <div class="center" v-if="newYearData.attendanceMonth.length"><span v-if="index<5" v-for="item,index in newYearData.attendanceMonth" :key="index">{{ item }}</span><span class="addNum" v-if="newYearData.attendanceMonth.length>5">+{{ newYearData.attendanceMonth.length - 5 }}</span></div>
          <div class="center" v-else>选择应用考勤月份</div>
          <i class="el-input__icon el-icon-circle-close" @click="mclear" v-if="isClose"></i>
          <el-date-picker
          class="months"
          type="months"
          value-format="yyyy-MM"
          v-model="newYearData.attendanceMonth"
          placeholder="选择应用考勤月份"
        >
        </el-date-picker>
        </div>
      </el-form-item>
      <div class="tips">红包应用的考勤月份，可多选</div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="newYearData.loading" @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<style>
  .months .el-input__inner{
    width:100%;
    height: 100% !important;
}
</style>
<style lang="scss" scoped>
.mbox{
  width:235px;
  color:#C0C4CC;
  border:1px solid #d9dee8;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding:0 5px;
  box-sizing: border-box;
  .el-icon-circle-close{
    cursor: pointer;
  }
  .months{
    position: absolute;
    top:0;
    left:0;
    width:200px;
    height: 100% !important;
    border:1px solid red;
    opacity: 0;
    input{
      height: 100% !important;
    }
  }
  .el-input__icon{
    line-height: 28px;
  }
  .center{
    flex:1;
    font-size:12px;
    span{
      padding:2px 4px;
      line-height: 20px;
      display: inline-block;
      margin:2px;
      background-color: #EEE;
      border-radius: 4px;
    }
  }
}
.mbox:hover{
  border:1px solid #C0C4CC;
}
.NewYearRedEnvelopeDialog {
  .el-form {
    .el-input-number {
      width: 100%;
    }
    :deep(.el-form-item__label) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #868686;
    }
    .tips {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c4c8cf;
      position: relative;
      top: -8px;
    }
  }
}
.addNum{
  // color:#999;
  margin-left:10px;
}
</style>
